*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Axiforma', sans-serif;
  transition: all 0.15s ease;
}

* a:active {
  scale: 0.95;
}

* a:hover {
  color: #51b48c;
}

@font-face {
  font-family: 'Axiforma';
  src: url('../src/Components/Assets/Font/Axiforma-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Axiforma';
  src: url('../src/Components/Assets/Font/Axiforma-Medium.ttf') format('truetype');
  font-weight: 500; 
  font-style: normal;
}

@font-face {
  font-family: 'Axiforma';
  src: url('../src/Components/Assets/Font/Axiforma-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
.container{
  width: 90%;
  margin: auto;
}
.title{
    text-align: center;
}
.title h2{
    color: #35543E;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 1px;
}
#work, #aboutus{
  padding-top: 30px;
}
