.footer{
    width: 100%;
    padding: 20px 5%;
    text-align: center;
    background: #51B48C;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer small{
    color: white;
}
.footer small a{
    color: white;
    text-decoration: none;
}
@media only screen and (max-width: 770px){
    .footer{
        flex-direction: column-reverse;
    }
}