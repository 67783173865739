.aboutus {
  width: 85%;
  margin: 0 auto ;
  padding-top: 90px;
}
.aboutus-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.left-div img {
  width: 100%;
}
.aboutus-row .left-div {
  flex-basis: 48%;
}
.aboutus-row .right-div {
    flex-basis: 48%;
  text-align: left;
}
.title {
  text-align: left;
  font-size: 48px;
  color: #51b88d;
  line-height: 70px;
  margin-bottom: 40px;
  text-shadow: 8px 15px 12px rgba(0, 0, 0, 0.15);



}
.right-div p, .about_us_text {
  text-align: left;
  color: #35543e;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 1px;
}
.about_us_text{
    margin-top: 100px;
}
@media only screen and (max-width: 1060px) {
  .aboutus-row {
    flex: wrap;
  }
  .aboutus-row .left-div{
    flex-basis: 100%;
  }
  .aboutus-row .right-div{
    margin-top: 50px;
    flex-basis: 100%;
  }
  .about_us_text{
    margin-top: 20px;
  }
}

