.row {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.top-div {
  text-align: center;
  padding-top: 160px;
  margin-bottom: 120px;
}
.top-div h1 {
  color: #51b48c;
  letter-spacing: 1px;
  line-height: 70px;
  font-weight: 700;
  font-size: 58px;
 text-shadow: 4px 10px 10px rgba(0, 0, 0, 0.2);


}
.top-div p {
  color: #35543e;
  font-size: 23px;
  line-height: 35px;
  font-weight: 400;
  margin-top: 20px;
  letter-spacing: 1px;
}
.bottom-div {
  width: 100%;
}
.bottom-div img {
  width: 100%;
  min-width: 350px;
  /* height: 400px;
  object-fit: cover;
  object-position: top; */
}
@media only screen and (max-width: 1060px) {
.top-div{
  padding-top: 150px;
}
.top-div h1{
  font-size: 48px;
  line-height: 60px;
}
  
}
@media only screen and (max-width: 770px){
 .top-div{
  width: 88%;
  margin: auto;
  margin-bottom: 50px;
 }
}