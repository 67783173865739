.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #dfdfdf;
  padding: 20px 5%;
  position: fixed;
  background-color: white;
  z-index: 99;
}
.logo  {
  color: #51b48c;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
}
nav ul li {
  display: inline-block;
}
nav ul li a {
  text-decoration: none;
  margin: 0 25px;
  display: inline-block;
  text-decoration: none;
  letter-spacing: 0.5;
  font-size: 18px;
  color: #494e4f;
}
.btn {
  background: linear-gradient(90deg, #50cb93, #54436b);
  border: none;
  color: white;
  border-radius: 8px;
  padding: 10px 35px;
  cursor: pointer;
}
@media only screen and (max-width: 770px){
  .logo p{
    /* background-color: red; */
  }
  nav{
    display: none;
  }
}
