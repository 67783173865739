.header {
  padding: 150px 5% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.left-div {
  flex-basis: 50%;
  min-width: 450px;
}
.left-div h1 {
  color: #51b88d;
  line-height: 90px;
  font-size: 56px;
}
.right-div {
  min-width: 450px;
  flex-basis: 50%;
  text-align: right;
}
.right-div img {
  width: 80%;
}
.article {
  padding: 30px 5%;
  color: #09120b;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
}
.article p {
  margin-bottom: 30px;
}
.article ul li {
  /* padding-left: 45px; */
  list-style-type: disc;
}
.article ul li strong{
  display: block;
}
.article ol{
  list-style: none;
}

@media only screen and (max-width: 1020px) {
  .left-div {
    text-align: left;
    flex-basis: 100%;
    margin-bottom: 70px;
  }
  .right-div {
    flex-basis: 100%;
    text-align: center;
  }
   .right-div img{
    width: 60%;
   }
}
@media only screen and (max-width: 770px) {
  .header {
    flex-wrap: wrap;
  }
  .left-div {
    flex-basis: 100%;
  }
  .right-div {
    flex-basis: 100%;
    text-align: center;
  }
  .left-div h1 {
    font-size: 45px;
    line-height: 60px;
  }
  .article{
    padding-left: 10%;
    padding-right: 10%;
  }
}
