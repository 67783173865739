.mission{
    padding-top: 100px;
    width: 85%;
    margin: auto;
}
.title-center{
    text-align: center;
    /* font-size: 25px; */
}
.card-container{
    display: grid;
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
      gap: 30px;
}
.card{
    padding: 40px 15px ;
    text-align: left;
    letter-spacing: 1px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 480px;
    transition: .3s;
}
.card:hover{
    transform: scale(1.05) translateY(-2px) ;
}
.card div img{
   width: 100%;
   max-width: 300px;
}
.card h3{
    margin-top: 5px;
    color: #000000;
    font-size: 20px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 30px;
}
.card p{
    color: #434343;
    font-size: 18px;
    line-height: 28px;
}
.card:first-child{
    background: #ecd3b9;
}
.card:nth-child(2){
    background: #6bd6ee;
}
.card:last-child{
    background: #93cef2;
}

@media only screen and (max-width: 1410px){
.card:last-child{
    margin: auto;
}
}
@media only screen and (max-width: 770px){
    .mission{
        margin-top: -50px;
    }
    .title-center{
        margin-bottom: 35px;
    }
}