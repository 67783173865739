.contact-us{
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
    gap: 50px;
}
.title-contactus{
    margin-top: 150px;
    text-align: center;
}
.div-left{
    flex-basis: 50%;
}
.div-right{
    flex-basis: 50%;
    text-align: center;
}
.div-left input{
    width: 70%;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid #828282;
    margin-bottom: 20px;
    min-width: 300px;
}
.div-left label{
    display: block;
    color: #3A3A3A;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
}
.div-left textarea{
    padding: 20px;
    border-radius: 15px;
    width: 70%;
    height: 150px;
     resize: none;   
     min-width: 300px;
}
.div-left input:focus {
  border-color: #50cb93; 
  outline: none;         
  box-shadow: 0 0 8px   #54436b; 
  min-width: 300px;
}
textarea:focus {
  border-color: #50cb93; 
  outline: none;         
  box-shadow: 0 0 8px   #54436b; 
}
.div-right img{
    width: 60%;
    min-width: 300px;
}
.send-btn{
    min-width: 300px;
    margin-top: 10px;
    width: 70%;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 15px;
}
@media only screen and (max-width: 1060px){
    .div-left{
        flex-basis: 100%;
    }
    .div-left input{
        width: 100%;
    }
    .div-left textarea{
        width: 100%;
    }
    .div-right{
        flex-basis: 100%;
    }
     .div-right img{
        width: 90%;
     }
    .send-btn{
        width: 100%;
        margin-top: 30px;
    }
}
@media only screen and (max-width: 770px){
    .title-contactus{
        margin-top: 60px;
        margin-bottom: 15px;
    }
    .contact-us{
        flex-wrap: wrap;
    }
    .contact-us{
        width: 85%;
        margin: auto;
    }
    .div-left input{
        padding: 13px;
    }
    .div-right img{
        width: 70%;
    }

}
